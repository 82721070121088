<template>
  <v-card outlined>
    <v-toolbar dense dark color="primary darken-1" elevation="0">
      <v-toolbar-title v-text="title" />
    </v-toolbar>

    <v-card-text>
      <v-data-table
        dense
        disable-pagination
        hide-default-footer
        :headers="headers"
        :items="items"
      >
        <template v-slot:[`item.src`]>
          <a href="#" v-text="'Download'" class="primary--text text--darken-1" @click="clickReport()"/>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import moment from 'moment'
import _ from 'lodash'
import eprs from '@/apps/eprs/utils/mixins/eprs.mixin'

export default {
  name: 'pibot-eprs-report-list',
  mixins: [eprs],
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    title: 'Reports',
    headers: [
      { text: 'Label', value: 'label' },
      { text: 'Link', value: 'src' },
      { text: 'Created on', value: 'generated_at' }
    ]
  }),
  computed: {
    ...mapState({
      reportMeta: state => state.eprs.reportMeta
    }),
    items () {
      return [
        {
          label: this.reportName,
          generated_at: moment(this.reportTimeStamp).format('DD MMM YYYY HH:mm:ss UTC Z')
        }
      ]
    },
    reportTimeStamp () {
      if (_.isEmpty(this.reportMeta)) return 0
      const element = this.reportMeta.filter((d) => d.incident_id === this.iid && parseInt(d.assessment_id) === this.aid)
      if (!element) return // some sort of warning or popup maybe saying no report has been found
      const timestamps = element.map(d => parseInt(d.timestamp))
      const timestamp = Math.max(...timestamps)
      return timestamp
    },
    reportName () {
      if (this.item?.assessment_type && this.reportTimeStamp) return 'EPRS_' + this.item.assessment_type + '_' + this.reportTimeStamp + '.pdf'
      else return ''
    }
  },
  async beforeMount () {
    await this.fetchMeta()
  },
  methods: {
    ...mapActions({
      fetchMeta: 'eprs/socket_fetchAllReportsMeta',
      fetchReport: 'eprs/socket_fetchReport'
    }),
    clickReport () {
      this.fetchReport(this.reportTimeStamp)
        .then(res => {
          const blob = new Blob([res.data])
          const link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.download = this.reportName
          link.click()
        })
    }
  }
}
</script>
